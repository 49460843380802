<template>
  <div style="position:relative; height:calc(100vh - 55px);">
    <button @click="downloadPdfReport(url_data,dashboard,user_name)" style="background-color: var(--primary_color);font-size: 18px;color:#ffffff; position: relative;top:5px;float: right;right: 25px;padding: 10px;border:0 none; border-radius: 33px;">Download as PDF</button>
    <iframe :src="url_data" frameborder="0" allowfullscreen
      style="position:relative;top:0;left:0;width:100%;height:100%;"></iframe>
  </div>
</template>
<script>
import commonFunction  from '../../mixin/commonFunction'
export default {
  name: "view_alchemer_reports",
  mixins: [commonFunction],
  mounted() {
    this.report_id = this.$route.params.url;
      document.body.classList.add('viewreporthidden');
      this.getSingleReport(this.report_id);
  },
  data() {
    return {
      report_id: null,
      url_data: null,
      dashboard: 'Employee Search',
      user_name: null,
    };
  },
  methods:{
    getSingleReport(id)
    {
      var apiUrl;
      if(this.$router.currentRoute.name == 'view_employee_reports'){
        this.dashboard = 'Employee Search';
        apiUrl = "/api/get-employee-report/";
      } else {
        if(this.$router.currentRoute.name == 'evaluator_employee_reports'){
          this.dashboard = 'Evaluators';
        } else{
          this.dashboard = 'Program Management Employees';
        }
        apiUrl = "/api/get-client-employee-report/";
      }
      var _this = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + apiUrl + id,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      };
      _this
      .axios(config)
      .then(({
        data
      }) => {
        var decodedJson = _this.decodeAPIResponse(data.data);
        _this.url_data = decodedJson.summary_report;            
        _this.user_name = decodedJson.user_name;            
      })
      .catch(({
        response
      }) => {
        _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        _this.$toast.error(response.data.error, {
          position: "top-right",
          duration: 5000,
        });
        _this.$router.push({
          name: "404",
        });
        /* _this.$toast.error("Please view a valid report.", {
        position: "top-right",
        duration: 5000,
        });*/
      });
    },
  }
};
</script>